import React, { useRef, useState, useCallback } from "react";
import Icon from "../elements/icon-svg";
import classNames from "classnames";
import Loadable from "@loadable/component";
import { Autocomplete } from "@react-google-maps/api";

const Modal = Loadable(() => import("react-overlays/Modal"));

const HubSearchBar = ({ search, address, cls = "" }) => {
  const [googleMapsGeocoder, setGoogleMapsGeocoder] = useState({});
  const [loading, setLoading] = useState(null);
  const addressField = useRef(null);
  const searchBox = useRef(null);
  const [addressText, setAddressText] = useState(address || "");
  const closeVisible = !!addressText?.length;

  const [warningModalVisible, setwarningModalVisible] = useState(false);

  const closeWarningModal = () => setwarningModalVisible(false);

  const renderBackdrop = (props) => (
    <button
      className="privacy-modal__backdrop"
      open={warningModalVisible}
      onClick={() => {
        setwarningModalVisible(false);
      }}
      {...props}
    />
  );

  const onSBLoad = useCallback(function onLoad(e) {
    searchBox.current = e;

    const gmObj = window.google.maps;
    if (!gmObj) return;
    const gmGeocoder = new gmObj.Geocoder();
    setGoogleMapsGeocoder(gmGeocoder);
  }, []);

  const getPosition = () => {
    if (loading) return;
    setLoading(true);
    if (window && "geolocation" in window.navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const { latitude, longitude } = position.coords;
          const latlng = new window.google.maps.LatLng(latitude, longitude);

          if (googleMapsGeocoder) {
            googleMapsGeocoder.geocode({ location: latlng }, function (results, status) {
              setLoading(false);
              if (status === window.google.maps.GeocoderStatus.OK) {
                addressField.current.value = results[0].formatted_address;
                search(addressField.current.value);
              } else {
                console.log("error");
              }
            });
          }
        },
        function (error) {
          console.error("Geolocation error: ", error);
          setwarningModalVisible(true);
          setLoading(false);
        }
      );
    } else {
      console.error("Error: Your browser doesn't support geolocation.");
    }
  };

  return (
    <div className={classNames("hub-search-bar", cls)}>
      <Autocomplete onLoad={onSBLoad} restrictions={{ country: ["IT"] }}>
        <>
          <input
            type="text"
            id="address"
            readOnly={loading}
            placeholder={"Indirizzo"}
            ref={addressField}
            value={addressText}
            onChange={(e) => setAddressText(e.target.value)}
          />
          <button
            className={classNames("hub-search-bar__close", {
              "hub-search-bar__close--visible": closeVisible,
            })}
            type="button"
            onClick={() => {
              setAddressText("");
              addressField.current.focus();
            }}
          >
            <span></span>
            <span></span>
          </button>
        </>
      </Autocomplete>
      <button
        className="cta-link"
        onClick={() => search(addressField?.current?.value)}
        disabled={loading}
      >
        CERCA
      </button>
      <span className="hub-search-bar__text">oppure</span>
      <button
        className="hub-search-bar__geolocation"
        aria-label="My position"
        onClick={() => {
          getPosition();
        }}
        disabled={loading}
      >
        <span className="hub-search-bar__map-icon">
          <Icon name="map-marker-alt" />
        </span>
        <span>LOCALIZZAMI</span>
      </button>
      <Modal
        show={warningModalVisible}
        onHide={closeWarningModal}
        className="modale-wrapper"
        renderBackdrop={renderBackdrop}
      >
        <div className="modale">
          <div className={"modale__content d-flex flex-lg-row justify-content-center"}>
            <button
              className="modale__close-btn hub-centri-assistenza__modale-close-btn"
              onClick={closeWarningModal}
            >
              X
            </button>
            <div className="col-lg-6 px-0">
              <div className="modale__text-box">
                <h1 className="modale__title text-center">Attenzione</h1>
              </div>
              <div className="d-flex flex-column flex-lg-row">
                <div className="modale__paragraph d-flex flex-column hub-centri-assistenza__modale-paragraph">
                  <h2 className="modale__paragraph-title text-left font-weight-bold">
                    Geocalizzazione non attiva
                  </h2>
                  <div className="modale__paragraph-content">
                    Per visualizzare correttamente questa sezione è necessario attivare la
                    geocalizzazione del browser.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default HubSearchBar;
